main { margin: auto; max-width: 960px; }

.table-container {
    margin: 10px;
    padding: 0;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
}

.fileUpload {
    width: 100%;
}

th {
    text-align: center !important;
}

td {
    min-width: 80px !important;
}

.refValue {
    color: yellow;
}

.cantEdit {
    width: 100px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.delete-file {
    cursor: pointer;
}

.expandableRow {
    cursor: pointer;
}

/*tr {*/
/*    border-color: black !important;*/
/*    border-width: 2px !important;*/
/*    border-collapse: collapse !important;*/
/*}*/

.cellDesigned {
    align-items: center;
}

.cellAttachment {
    cursor: pointer;
}

.notesTooltip {
    height: 100%;
    margin-left: 5px;
    display: flex;
}

.attachmentLink {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

/*table {*/
/*    border-color: black !important;*/
/*    border-width: 2px !important;*/
/*    border-radius: 4px !important;*/
/*    background: white;*/
/*    padding: 10px;*/
/*    font-size: 14px;*/
/*    border-collapse: collapse !important;*/
/*}*/

.phaseRecord {
    overflow: auto;
}

.file-details {
    display: flex;
    align-items: center;
}

.file-upload-stats {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.list-of-files {
    align-self: flex-start;
    margin-left: 20px;
    margin-top: 10px;
}

.image-upload>input {
    display: none;
}

.image-upload>label {
    display: flex;
    cursor: pointer;
    align-items: center;
    flex-direction: row;
}

.modalPhaseRecordContent {
    width: 80% !important;
    min-height: 95vh !important;
}

.phaseRecord::-webkit-scrollbar {
    width: 1px !important;
}

.phaseRecord::-webkit-scrollbar-track {
}

.phaseRecord::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: black;
    border-radius: 20px;
}

/*tr.expandable > td {*/
/*    box-shadow: inset 0 3px 6px -3px rgba(0, 0, 0, .2);*/
/*    font-size: 14px;*/
/*}*/

.confirmPhaseSendModal {
    width: 200px !important;
}

tr.expandable > td > .inner {
    margin: 15px;
    overflow: hidden;
}